<template>
  <CSidebar
    size="sm"
    position="fixed"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="companyName">
      {{ user_info.companyName }} <p v-if="user_info.companyJoinStatus === 'I'" style="font-size: 0.7rem; margin: 0rem;">{{user_info.companyJoinStatus === 'I'?'(입점준비중)':''}}</p>
      <!-- <CIcon
        custom-class-name="sidebar-brand-full"
        :icon="sygnet"
        :height="35"
        @click="$router.push('/')"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      /> -->
    </CSidebarBrand>
    <AppSidebarNav />
    <CButtonGroup data-agreement_wrap vertical role="group" aria-label="Vertical button group">
      <CButton color="warning" size="sm" type="button">
        <a href="https://forms.gle/xb4j8X4odSSDgCVv5" target="_blank">마켓뱅 이벤트 등록</a>
      </CButton>
      <CButton color="warning" size="sm" type="button" @click="()=>{useGuideOpen()}">이용가이드 바로가기</CButton>
      <CButton color="dark" size="sm" type="button" @click="()=>{agreement1_popup=true}">서비스 이용약관</CButton>
      <CButton color="dark" size="sm" type="button" @click="()=>{agreement2_popup=true}">판매자 개별약관</CButton>
      <CButton color="dark" size="sm" type="button" @click="()=>{agreement3_popup=true}">개인정보 취급방침</CButton>
    </CButtonGroup>
    <dl class="bottom_info">
      <dt>쓰리랩스(주)</dt>
      <dd>사업자등록번호: 361-87-00890</dd>
      <dd>문의사항: 02-3273-2869</dd>
      <dd>E-mail: <a href="mailto:contact@marketbang.kr">contact@marketbang.kr</a></dd>
    </dl>
  </CSidebar>
<CModal :visible="agreement1_popup" @close="()=>{ agreement1_popup = false }">
  <CModalHeader><CModalTitle>서비스 이용약관</CModalTitle></CModalHeader>
  <CModalBody><UseTerms /></CModalBody>
</CModal>
<CModal :visible="agreement2_popup" @close="()=>{ agreement2_popup = false }">
  <CModalHeader><CModalTitle>판매자 개별약관</CModalTitle></CModalHeader>
  <CModalBody><SellerTerms /></CModalBody>
</CModal>
<CModal :visible="agreement3_popup" @close="()=>{ agreement3_popup = false }">
  <CModalHeader><CModalTitle>개인정보 취급방침</CModalTitle></CModalHeader>
  <CModalBody><PrivacyPolicy /></CModalBody>
</CModal>
</template>
<style lang="sass">
[data-agreement_wrap]
  margin-bottom: 40px
  a
    text-decoration: none
    color: rgba(44, 56, 74, 0.95)
.companyName
  font:
    size: 16px
    weight: bold
.bottom_info
  display: flex
  flex-direction: column
  align-items: center
  dt
    margin-bottom: 10px
    font-size: 16px
  a
    color: #fff
    text-decoration: none
    &:hover
      text-decoration: underline
  dd
    width: 171px
    margin-bottom: 0
    font-size: 12px
    /*&:last-child
      margin-top: 10px
      font-size: 14px
      text-align: center*/
</style>
<script>
import {computed, ref} from "vue";
import { useStore } from "vuex";
import { AppSidebarNav } from "./AppSidebarNav";
import { logoNegative } from "@/assets/brand/logo-negative";
import { sygnet } from "@/assets/brand/sygnet";
import UseTerms from "@/views/terms/UseTerms.vue";
import PrivacyPolicy from "@/views/terms/PrivacyPolicy.vue";
import SellerTerms from "@/views/terms/SellerTerms.vue";
export default {
  "name" : "AppSidebar",
  "components" : {AppSidebarNav,UseTerms, PrivacyPolicy, SellerTerms},
  setup(){
    const store = useStore();
    const agreement1_popup = ref( false );
    const agreement2_popup = ref( false );
    const agreement3_popup = ref( false );

    return {
      agreement1_popup, agreement2_popup, agreement3_popup,
      logoNegative, sygnet,
      "sidebarVisible" : computed(()=>store.state.sidebarVisible),
      "user_info" : computed(()=>store.state.user_info),
    };
  },
  methods: {
    useGuideOpen(){
      window.open("https://www.notion.so/threelabs/70d05402047542ef8d243089feaa22d3", "_blank");
    }
  }
}
</script>
