<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 pb-5">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { CContainer } from '@coreui/vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Category from "@/api/common/category";

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
  },
  setup(){
    const store = useStore();
    Category.getProducer().then(( res )=>{
      store.commit("userInfoInsert", res.data.data);
    });
  }
}
</script>
<!-- ROLE_SELLER_MANAGER -->
<!-- ROLE_SELLER_OWNER -->